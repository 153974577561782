import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";

import { Context as AuthContext } from "../../../../Context/AuthContext";
import ProductContainer from "../../../../component/ProductContainer/ProductContainer";
import Card from "../../../../component/Card";

import ScrollMenu from "react-horizontal-scrolling-menu";

const RecentViewed = ({ data, banners, recent }) => {
  const navigate = useNavigate();
  const { state } = useContext(AuthContext);
  const { t, i18n } = useTranslation();
  const [recentViewedItems, setRecentViewedItems] = useState([]);
  const [myFilter, setMyFilter] = useState("DESC");
  const [selected, setSelected] = useState("");

  useEffect(() => {
    setRecentViewedItems(recent);
  }, [state?.token]);

  const navigateToCAtegoryDetails = () => {
    //   navigate("/CategoryDetials");
  };

  return (
    <div className="container-fluid d-flex flex-column">
      <div className="row">
        {recentViewedItems?.length > 0 && (
          <div className="col-12 my-5">
            <div className="d-flex">
              <h2
                onClick={() => navigateToCAtegoryDetails()}
                className="pr-4 border-right"
                style={{ cursor: "pointer" }}
              >
                {t("Recent Viewed items")}
              </h2>
              <p
                onClick={() => navigateToCAtegoryDetails()}
                className="mx-4"
                style={{ fontSize: 24, cursor: "pointer" }}
              >
                {t("See all")}
                <span className="mx-2" style={{ fontSize: 28 }}>
                  &#8594;
                </span>
              </p>
            </div>
            <ScrollContainer
              hideScrollbars={false}
              vertical={false}
              horizontal={true}
              className="scroll-container"
            >
              <div className="d-flex">
                {recent?.map((item, index) => {
                  return <Card key={index} data={item} />;
                })}
              </div>
            </ScrollContainer>
          </div>
        )}
        <div className="col-12">
          <ProductContainer data={data} banners={banners} />
        </div>
      </div>
    </div>
  );
};

export default RecentViewed;
