import React,{useContext} from "react";
import { useNavigate } from "react-router-dom";

import {Context as mainContext} from "../../../../Context/mainContext";

const BrandList = ({ data }) => {
    const navigate = useNavigate();
    const { addBrandId } = useContext(mainContext);
    const navigateToBrandDetails=()=>{
        navigate({
                pathname: `/Brands/${data.title}`,
                state: {
                    _id: data.id,
                    _name:data.title
                }
            })
        addBrandId(data.id)
    }
    return (
        <div onClick={()=>navigateToBrandDetails()} style={{ cursor: "pointer"}} className="d-flex m-3 border p-2 custome_card">
            <p className="p-0 m-auto">{data.title}</p>
        </div>
    )
}

export default BrandList;