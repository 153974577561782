import React, { useContext } from "react";
import { Accordion, Button, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import Theme from "../../../../styles/theme";
import { Context as mainContext } from "../../../../Context/mainContext";

const Card = ({ data }) => {
    const { addBrandId,changeItemId,changeSubCategoryId } = useContext(mainContext);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const rootTitle = data?.root?.title?.replace(/ /g,"_")
    const categoryTitle = data?.category?.title?.replace(/ /g,"_")
    const navigateToDetails = () => {
        navigate({
            pathname: `/${rootTitle}/${categoryTitle}/${data?.id}`,
            state: {
                _id: data.id,
            }
        })
        changeItemId(data?.id)
    }

    const navigateToBrandDetails = () => {
        navigate({
            pathname: `/BrandsDetails/${data?.brand?.title}`
        })
        addBrandId(data?.brand?.id)
    
    }

    const navigateToCategoryPage = () => {
        navigate({
            pathname: '/CategoryDetials'
        })
    }

    const navigateToCategory = () => {
        navigate({
            pathname: `/${rootTitle}/${categoryTitle}`,
            state: {
                _subCategoryId: data?.category?.id,
            }
        })
        changeSubCategoryId(data?.category?.id)
    }
    const title = data?.title.substring(0,20);
    return (
        <div className="d-flex border rounded p-2 my-2">
            <Image onClick={() => navigateToDetails()} style={{ objectFit: "contain", cursor: "pointer", height: 250 }} width="200" src={data?.thumbnail} />
            <div className="mx-2">
                <p style={{ fontSize: 12, color: Theme.colors.darkGrey }}><span>{data?.root?.title}</span><span>{" "}>{" "}<span onClick={()=>navigateToCategory()} style={{ cursor: "pointer" }}>{data?.category?.title}</span>{" "}>{" "}<span onClick={() => navigateToDetails()} style={{ cursor: "pointer" }}>{title}...</span></span></p>
                <p style={{ fontSize: 12, color: Theme.colors.darkGrey }}>{t('Sponsored')}</p>
                <h6>{data?.title}</h6>
                <p style={{ fontSize: 12, color: Theme.colors.darkGrey }}>{t('Brand New')}</p>
                <div className="w-100 d-flex justify-content-between align-items-center">
                    <h3>US ${data?.price_total}</h3>
                    <p className="m-0" style={{ fontSize: 12, color: Theme.colors.darkGrey }}>{t('From')} {data?.country?.name}</p>
                </div>
                <div className="w-100 d-flex justify-content-between align-items-center">
                    <p className="m-0" style={{ fontSize: 12, color: Theme.colors.darkGrey }}>{t('Buy it Now')}</p>
                    <p onClick={() => navigateToDetails()} className="m-0" style={{ fontSize: 12, color: Theme.colors.darkGrey, cursor: "pointer" }}>{t('More Details')}</p>
                </div>
                <p className="my-2" style={{ fontSize: 12, color: Theme.colors.darkGrey }}>{t('Free International Shipping')}</p>
                <p className="my-2" style={{ fontSize: 12, color: Theme.colors.danger }}><b>{data?.quantity} {t('left')}</b></p>
            </div>
        </div>
    )
}

export default Card;