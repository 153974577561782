import axios from "axios";

const Constants = {
    apiUrl: "http://api.ateej.net/en",
    headers: {
        // Accept: "application/json",
        // "Content-Type": "application/json",
    },
};

const instance = axios.create({
    baseURL: Constants.apiUrl,
});

instance.interceptors.request.use(
    async (config) => {
        config.headers = {
            ...Constants.headers,
        };
        const token = await localStorage.getItem("accessToken");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },

    (err) => {
        return Promise.reject(err);
    }
);

export default instance;
