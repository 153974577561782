import React, { useEffect, useState, useContext } from "react";
import { IoIosNotificationsOutline } from "react-icons/io";
import { BiCart } from "react-icons/bi";
import axios from "axios";
import ReactLoading from "react-loading";
import { useTranslation } from "react-i18next";

import { Context as AuthContext } from "../../../Context/AuthContext";
import DropDown from "../../DropDown";
import ClickedItem from "./component/ClickedItem";
import Theme from "../../../styles/theme";

export const textStyle = { margin: 5, fontSize: 12 };

const DropDownData = [
  {
    id: 1,
    title: "title1",
    navigateto: "notificationTitle1",
  },
  {
    id: 2,
    title: "title2",
    navigateto: "notificationTitle2",
  },
  {
    id: 3,
    title: "title3",
    navigateto: "notificationTitle3",
  },
];
const TextStyle = {
  marginInline: 2,
  marginBlock: 5,
  fontSize: 12,
  overFlow: "auto",
  whiteSpace: "nowrap",
};

const MainHeader = () => {
  const { state } = useContext(AuthContext);
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [personData, setPersonData] = useState({});
  const [responseError, setResponseError] = useState({});
  const [haveAccessToken, setHaveAccessToken] = useState(null);
  const [categoryData, setCategoryData] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [userData, setUserData] = useState({});
  const getAccessToken = async () => {
    const token = await localStorage.getItem("accessToken");
    setHaveAccessToken(token);
    return token;
  };

  const getUserData = async () => {
    const data = await localStorage.getItem("userData");
    setUserData(JSON.parse(data));
    setPersonData(JSON.parse(data));
    return JSON.parse(data);
  };

  useEffect(() => {
    getAccessToken();
    getUserData();
    const itemsArray = JSON.parse(localStorage.getItem("itemsArray")) || [];
    setCartItems(itemsArray);
    (async () => {
      setIsLoading(false);
      try {
        await axios({
          method: "get",
          url: "http://api.ateej.net/en/api/items/menu/top/1",
        })
          .then((res) => {
            const response = res.data.context;
            setCategoryData(response);
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            const errorToSend = error?.response?.statusText.replace(
              / /g,
              "_"
            )(async () => {
              try {
                await axios({
                  method: "post",
                  url: `http://api.ateej.net/en/api/logs/web/${errorToSend}`,
                })
                  .then((res) => {
                    console.log(res);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
                setIsLoading(false);
              } catch (error) {
                setIsLoading(false);
                console.log(error);
              }
            })();
          });
        await axios({
          method: "get",
          url: "http://api.ateej.net/en/api/auth_user/profile/",
          headers: {
            Authorization: `Bearer ${haveAccessToken}`,
          },
        })
          .then((res) => {
            const response = res.data.context;
            setIsLoading(false);
            setPersonData(response);
          })
          .catch((error) => {
            console.log({ error });
            setIsLoading(false);
            const errorToSend = error?.response?.statusText.replace(/ /g, "_");
            (async () => {
              try {
                await axios({
                  method: "post",
                  url: `http://api.ateej.net/en/api/logs/web/${errorToSend}`,
                })
                  .then((res) => {
                    console.log(res);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
                setIsLoading(false);
              } catch (error) {
                setIsLoading(false);
                console.log(error);
              }
            })();
          });
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [state?.token, state?.changeCartItems]);

  return (
    <>
      {isLoading === true ? (
        <div
          style={{ height: "100vh" }}
          className="d-flex justify-content-center align-items-center"
        >
          <ReactLoading
            type="bubbles"
            height={150}
            width={150}
            color={Theme.colors.darkGrey}
          />
        </div>
      ) : (
        <div className="d-none d-lg-block container-fluid">
          <div className="row border-bottom">
            <div className="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-start">
              {haveAccessToken == null ? (
                <div className="d-flex my-auto">
                  <p style={TextStyle}>{t("Hi!")}</p>
                  <a className="mx-3" href="/login">
                    <p style={TextStyle}>{t("Sign in")}</p>
                  </a>
                  <p style={TextStyle}>{t("or")}</p>
                  <a className="mx-3" href="/signup">
                    <p style={TextStyle}>{t("register")}</p>
                  </a>
                </div>
              ) : (
                <div className="d-flex my-auto">
                  <p style={TextStyle}>{t("Hi!")}</p>
                  <a className="mx-3" href={`/Profile/${personData?.username}`}>
                    <p style={TextStyle}>
                      <b>
                        {personData?.first_name} {personData?.last_name}
                      </b>
                    </p>
                  </a>
                </div>
              )}
              {/* <ClickedItem data={categoryData} /> */}
            </div>
            <div className="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end align-items-center">
              {/* <DropDown
                responsive={true}
                title={t("Watchlist")}
                items={DropDownData}
              />
              <DropDown
                responsive={false}
                title={t("My Ateej")}
                items={DropDownData}
              /> */}
              <DropDown
                responsive={false}
                personData={personData}
                Profile={true}
                items={DropDownData}
              />
              <DropDown
                responsive={false}
                languages={true}
                items={DropDownData}
              />
              <div className="d-flex">
                {/* <IoIosNotificationsOutline
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate({ pathname: "/Notifications" })}
                  className="mx-3 my-auto"
                  size={20}
                /> */}
                {/* <div
                  className="m-0 p-0 d-flex"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate({ pathname: "/Cart" })}
                >
                  <BiCart
                    className="my-auto p-0"
                    size={cartItems.length > 0 ? 25 : 20}
                  />
                  {cartItems.length > 0 && (
                    <div
                      className="rounded-circle d-flex justify-content-center"
                      style={{
                        width: 15,
                        height: 15,
                        backgroundColor: Theme.colors.danger,
                      }}
                    >
                      <p
                        className="my-0 mx-auto p-0 rounded-circle"
                        style={{ fontSize: 10, color: Theme.colors.white }}
                      >
                        {cartItems.length}
                      </p>
                    </div>
                  )}
                </div> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MainHeader;
