import React,{useContext} from "react";
import { Dropdown } from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {useWindowSize} from "../../../../../../../hooks/useWindowSize";
import {Context as mainContext} from "../../../../../../../Context/mainContext";

const QuickSearchResult = ({data}) =>{
    const navigate = useNavigate();
    const {changeItemId} = useContext(mainContext);
    const size = useWindowSize();
    const rootTitle = data?.root?.title?.replace(/ /g,"_")
    const categoryTitle = data?.category?.title?.replace(/ /g,"_")
    const navigateToItemDetails =()=>{
        navigate({
            pathname: `/${rootTitle}/${categoryTitle}/${data?.id}`,
            state: {
                _id: data?.id,
            }
        })
      changeItemId(data?.id)
    }
    const boxWidth=size.width>768?80:size.width>500?80:30
    const title = data?.title?.substring(0,boxWidth);
    return(
        <Dropdown.Item onClick={()=>navigateToItemDetails()}>
            <p>
                {title}...
            </p>
        </Dropdown.Item>
    )
}

export default QuickSearchResult;