import React from "react";
import {useNavigate} from "react-router-dom";

const BrandList =({data})=>{
    const navigate = useNavigate();
    const navigateToBrands =()=>{
        navigate({
            pathname:`/Brands/${data.title}`,
            state: {
                _id: data.id,
                _name:data.title
            }
        })
    }
    return(
        <div onClick={()=>navigateToBrands()} style={{ cursor: "pointer"}} className="d-flex m-3 border p-2 custome_card">
            <p className="p-0 m-auto">{data?.title}</p>
        </div>
    )
}

export default BrandList;