import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Context as mainContext } from "../../../../Context/mainContext";

const SubCategoryList = ({ data, rootTitle }) => {
  const navigate = useNavigate();
  const { changeSubCategoryId, changeSubCategoryName } = useContext(
    mainContext
  );
  const changeSubCategory = () => {
    const categoryTitle = data?.title?.replace(/ /g, "_");
    const roottitle = rootTitle?.replace(/ /g, "_");
    navigate({
      pathname: `/${roottitle}/${categoryTitle}`,
      state: {
        _subCategoryId: data?.id,
      },
    });
    changeSubCategoryId(data?.id);
    changeSubCategoryName(data?.title);
  };
  return (
    <div>
      <p
        onClick={() => changeSubCategory()}
        className="border-bottom custome_card"
        style={{ cursor: "pointer", fontSize: 12 }}
      >
        {data.title}
      </p>
    </div>
  );
};

export default SubCategoryList;
