import React,{useEffect,useState} from "react";
import {useNavigate} from "react-router-dom";
import axios from 'axios';
import ReactLoading from 'react-loading';
import { useTranslation } from 'react-i18next';

import Card from "./components/Card/Card";
import Theme from "../../styles/theme";

const Brands =()=>{
    const navigate = useNavigate();
    const { t, i18n } = useTranslation()
    const [isLoading,setIsLoading] =useState(false);
    const [brand,setBrandResponse] =useState([]);
    const [responseError, setResponseError] =useState([]);
        useEffect(() => {
        (async () => {
            setIsLoading(true);
            try {
                await axios({
                    method: 'get',
                    url: 'http://api.ateej.net/en/api/brands/',
                }).then(res => {
                    setIsLoading(false);
                    const brandResponse = res.data.context;
                    setBrandResponse(brandResponse);
                }).catch(error => {
                    setIsLoading(true);
                    const errorToSend = error?.response?.statusText.replace(/ /g, "_")
                    error.response.status == 404 && navigate("/NotFound") 
                    (async()=>{
                        try {
                            await axios({
                                    method:'get',
                                    url:`http://api.ateej.net/en/api/log/web/${errorToSend}`
                                }).then(res => {
                                    const errorCatched = res.data;
                                    setResponseError(errorCatched);
                                }).catch(error=>{
                                    console.log(error);
                                })   
                                setIsLoading(false);
                            } catch (error) {
                                setIsLoading(false);
                                console.log(error);
                            }
                    })()
                })
                setIsLoading(false);
            } catch (error) {
                console.log(error);
            }
        })();
    }, [])
    return(
        <>
            {
                isLoading === true ? <div style={{ height: "100vh" }} className="d-flex justify-content-center align-items-center">
                    <ReactLoading type="bubbles" height={150} width={150} color={Theme.colors.darkGrey} /></div> :
                    <div className="container-fluid h-100 my-5">
                        <div className="row">
                        <h4 className="col-12">{t('available brands')}</h4>
                            <div className="col-12 d-flex flex-wrap justify-content-center">
                                {
                                    brand?.map((item)=>{
                                        return(
                                            <Card key={item.id} data={item}/>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
            }
</>
    )
}

export default Brands;