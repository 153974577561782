import React,{useContext} from "react";
import { useNavigate } from "react-router-dom";
import { Image } from "react-bootstrap";

import {Context as mainContext} from "../../../../Context/mainContext";
import Theme from "../../../../styles/theme";

const Card =({data})=>{
    const navigate = useNavigate();
    const {changeItemId} = useContext(mainContext);
    const rootTitle = data?.root?.title?.replace(/ /g,"_")
    const categoryTitle = data?.category?.title?.replace(/ /g,"_")
    const navigateToDetails=()=>{
        navigate({
            pathname: `/${rootTitle}/${categoryTitle}/${data?.id}`,
            state: {
                _id: data?.id,
            }
        })
        changeItemId(data?.id)
    }
    const title = data?.title?.substring(0, 40);
    return(
        <div style={{width:200, backgroundColor:Theme.colors.white }} className="m-3 p-2 rounded">
            <Image onClick={()=>navigateToDetails()} src={data?.thumbnail} style={{cursor:"pointer",objectFit:"cover"}} height="200" width="100%" />
            <p style={{ width: 200, textOverflow: "ellipsis" }}>{title}</p>
            <p style={{ color: Theme.colors.darkGrey }}>${data.price_total}</p>
        </div>
    )
}

export default Card;