import React, { useEffect, useState, useContext, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";
import axios from "axios";
import Rating from "react-rating";
import { AiFillStar } from "react-icons/ai";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { Image, Button } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";

import useScrollBox from "../../hooks/useScrollBox";
import Card from "./component/Card";
import { Context as MainContext } from "../../Context/mainContext";
import { Context as AuthContext } from "../../Context/AuthContext";
import Theme from "../../styles/theme";
import AteejLogo from "../../assets/images/AteejLogo.png";
import { useApi } from "../../hooks/useFetch";

const token = localStorage.getItem("accessToken");

const ItemDetails = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const scrollWrapperRef = useRef();
  const { isDragging } = useScrollBox(scrollWrapperRef);
  const [getProfileAny, profileAnyResponse] = useApi();
  const { state, addtocart, changeSubCategoryId } = useContext(MainContext);
  const { changeCartItemsAuthContext } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [added, setAdded] = useState(false);
  const [actually, setActuallyExist] = useState(false);
  const [data, setData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [images, setImages] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [cartItemsLength, setCartItemsLength] = useState(0);
  const [relatedItems, setRelatedItems] = useState([]);
  const [showNumber, setShowNumber] = useState(false);
  const incommingState = location.pathname.split("/")[3];

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const Items = JSON.parse(localStorage.getItem("itemsArray")) || [];
        setCartItemsLength(Items.length);
        setCartItems(Items);
        await axios
          .get(`http://api.ateej.net/en/api/items/details/${incommingState}`)
          .then((res) => {
            const response = res.data;
            setData(response?.context);
            setImages(response?.context?.thumbnail);
            setRelatedItems(response?.related_items);
          })
          .catch((error) => {
            const errorToSend = error?.response?.statusText?.replace(/ /g, "_");
            error?.response?.status == 404 && navigate("/NotFound");
            setIsLoading(true);
            (async () => {
              try {
                await axios({
                  method: "post",
                  url: `http://api.ateej.net/en/api/logs/web/${errorToSend}`,
                });
                setIsLoading(false);
              } catch (error) {
                setIsLoading(false);
                console.log(error);
              }
            })();
          });
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [location, state?.itemId]);

  useEffect(() => {
    getProfileAny("get", `/en/api/auth_user/profile/any/${data?.seller?.id}`);
  }, [data]);

  const navigateToProfile = () => {
    navigate({
      pathname: `/User/${data?.seller?.name}`,
      state: {
        _id: data?.seller?.id,
      },
    });
  };

  const navigateToContact = () => {
    navigate({
      pathname: `/Contact/${data?.seller?.name}`,
      state: {
        _id: data?.seller?.id,
      },
    });
  };

  const navigateToCheckoutItem = (data) => {
    navigate({
      pathname: "/CheckoutItem",
      state: {
        item: data,
      },
    });
  };
  const SaveDataToLocalStorage = (data) => {
    let CartItems;
    if (localStorage.getItem("itemsArray") === null) {
      CartItems = [];
    } else {
      CartItems = JSON.parse(localStorage.getItem("itemsArray"));
    }
    const findData = CartItems.findIndex((item) => item.id === data.id);
    if (findData === -1) {
      CartItems.push(data);
      setCartItemsLength(cartItems.length + 1);
      setAdded(true);
    } else {
      setCartItemsLength(cartItems.length);
      CartItems.splice(findData, findData + 1, data);
      setActuallyExist(true);
    }
    localStorage.setItem("itemsArray", JSON.stringify(CartItems));
    saveAvailableQuantity(data);
  };
  const saveAvailableQuantity = (data) => {
    let availableQuantityArray;
    if (localStorage.getItem("availableQuantity") === null) {
      availableQuantityArray = [];
    } else {
      availableQuantityArray = JSON.parse(
        localStorage.getItem("availableQuantity")
      );
    }
    const findData = availableQuantityArray.findIndex(
      (item) => item.id === data.id
    );
    if (findData === -1) {
      availableQuantityArray.push({
        id: data.id,
        availableQuantity: 1,
        price_total: data.price_total,
        title: data.title,
      });
      setCartItemsLength(cartItems.length + 1);
      setAdded(true);
    } else {
      availableQuantityArray.splice(findData, findData + 1, {
        id: data.id,
        availableQuantity: 1,
        price_total: data.price_total,
        title: data.title,
      });
      setActuallyExist(true);
    }
    localStorage.setItem(
      "availableQuantity",
      JSON.stringify(availableQuantityArray)
    );
  };

  const closeAddedAlert = () => {
    changeCartItemsAuthContext();
    setAdded(false);
  };

  const closeAddedToCart = () => {
    changeCartItemsAuthContext();
    setAdded(false);
    navigate("/Cart");
  };

  const closeActuallyAlert = () => {
    changeCartItemsAuthContext();
    setActuallyExist(false);
  };

  const GoToCart = () => {
    changeCartItemsAuthContext();
    setActuallyExist(false);
    navigate("/Cart");
  };

  const ContinueShipping = () => {
    console.log("ContinueShipping");
  };

  const RedNumber = () => {
    return (
      <h4 className="m-0 p-0" style={{ color: "#000" }}>
        {cartItemsLength} <span>{t("item added to cart successful")}</span>
      </h4>
    );
  };
  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
  const ItemBody = useRef(null);
  const navigateToBodyItem = () => scrollToRef(ItemBody);
  const changeNextImage = () => {
    setPhotoIndex((photoIndex + 1) % images.length);
  };
  const changePreviousImage = () => {
    photoIndex > 0
      ? setPhotoIndex((photoIndex + images.length - 1) % images.length)
      : setPhotoIndex(images.length - 1);
  };
  const categoryTitle = data?.category?.title?.replace(/ /g, "_");
  const navigateToCategory = () => {
    navigate({
      pathname: `/${categoryTitle}`,
      state: {
        _subCategoryId: data?.category?.id,
      },
    });
    changeSubCategoryId(data?.category?.id);
  };

  const navigateToReviews = () => {
    navigate({
      pathname: `${location?.pathname}/Reviews`,
    });
  };

  const navigateToUserProfile = () => {
    console.log("pressed!");
  };
  const size = 20;

  const ratesLevels = [
    {
      id: 1,
      value: data?.rating?.score1_count,
    },
    {
      id: 2,
      value: data?.rating?.score2_count,
    },
    {
      id: 3,
      value: data?.rating?.score3_count,
    },
    {
      id: 4,
      value: data?.rating?.score4_count,
    },
    {
      id: 5,
      value: data?.rating?.score5_count,
    },
  ];
  console.log({ data: data?.rating?.score1_count });
  return (
    <>
      {isLoading === true ? (
        <div
          style={{ height: "100vh" }}
          className="d-flex justify-content-center align-items-center"
        >
          <ReactLoading
            type="bubbles"
            height={150}
            width={150}
            color={Theme.colors.darkGrey}
          />
        </div>
      ) : (
        <div className="container-fluid mb-5">
          <div className="row">
            <p
              className="ml-3"
              style={{ fontSize: 12, color: Theme.colors.darkGrey }}
            >
              <span style={{ cursor: "pointer" }}>{data?.root?.title}</span>
              <span>
                {" "}
                >{" "}
                <span
                  onClick={() => navigateToCategory()}
                  style={{ cursor: "pointer" }}
                >
                  {data?.category?.title}
                </span>{" "}
                > <span>{data?.item_serial}</span>
              </span>
            </p>
            <h3 ref={ItemBody} className="col-12">
              {data.title}
            </h3>
            <div className="col-12 d-flex align-items-center border-bottom pb-2 mb-3">
              <Rating
                readonly={true}
                initialRating={data?.rating?.main}
                emptySymbol={
                  <AiFillStar size={32} color={Theme.colors.darkGrey} />
                }
                placeholderSymbol={
                  <AiFillStar size={32} color={Theme.colors.yellow} />
                }
                fullSymbol={
                  <AiFillStar size={32} color={Theme.colors.yellow} />
                }
              />
              <p
                className="my-0 mx-2"
                onClick={() => navigateToReviews()}
                style={{ color: Theme.colors.blue, cursor: "pointer" }}
              >
                {data?.rating?.votes} {t("product ratings")}
              </p>
              <p className="my-0 mx-2" style={{ color: Theme.colors.blue }}>
                <span
                  style={{ cursor: "pointer", color: Theme.colors.blue }}
                  onClick={() => navigateToBodyItem()}
                >
                  {t("About this product")}
                </span>
              </p>
              <div className="d-flex flex-column justify-content-center w-25 mx-3">
                {ratesLevels?.map((item, index) => {
                  return (
                    <div key={item?.id} className="d-flex align-items-center">
                      <AiFillStar size={size} color={Theme.colors.darkGrey} />
                      <p
                        className="my-0 mx-1"
                        style={{ color: Theme.colors.darkGrey }}
                      >
                        {item?.value}
                      </p>
                      <Progress
                        percent={item?.value}
                        theme={{
                          active: {
                            color: Theme.colors.darkBlue,
                            symbol: "10",
                          },
                        }}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="d-flex mx-2">
                <div className="d-flex flex-column align-items-center justify-content-center mx-2">
                  <Progress
                    type="circle"
                    strokeWidth={3}
                    percent={data?.rating?.first?.percentage}
                    theme={{
                      active: {
                        color: Theme.colors.darkGrey,
                      },
                    }}
                  />
                  <p className="text-center" style={{ height: 20 }}>
                    {data?.rating?.first?.name}
                  </p>
                </div>
                <div className="d-flex flex-column align-items-center justify-content-center mx-2">
                  <Progress
                    type="circle"
                    strokeWidth={3}
                    percent={data?.rating?.second?.percentage}
                    theme={{
                      active: {
                        color: Theme.colors.darkGrey,
                      },
                    }}
                  />
                  <p style={{ height: 20 }}>{data?.rating?.second?.name}</p>
                </div>
                <div className="d-flex flex-column align-items-center justify-content-center mx-2">
                  <Progress
                    type="circle"
                    strokeWidth={3}
                    percent={data?.rating?.third?.percentage}
                    theme={{
                      active: {
                        color: Theme.colors.darkGrey,
                      },
                    }}
                  />
                  <p style={{ height: 20 }}>{data?.rating?.third?.name}</p>
                </div>
                <Button
                  onClick={() => navigateToReviews()}
                  className="my-0 py-1 px-2 rounded-0 h-100 w-100"
                >
                  {t("write a review")}
                </Button>
              </div>
            </div>
            <div className="col-12 d-flex justify-content-between align-items-center my-2">
              <div>
                {data?.reviews?.map((item, index) => {
                  const size = 20;
                  return (
                    <div
                      className="d-flex justify-content-center align-items-end"
                      key={item?.id}
                    >
                      <div className="border-bottom px-2">
                        <Rating
                          readonly={true}
                          placeholderRating={item?.score}
                          emptySymbol={
                            <AiFillStar
                              size={size}
                              color={Theme.colors.darkGrey}
                            />
                          }
                          placeholderSymbol={
                            <AiFillStar
                              size={size}
                              color={Theme.colors.yellow}
                            />
                          }
                          fullSymbol={
                            <AiFillStar
                              size={size}
                              color={Theme.colors.yellow}
                            />
                          }
                        />
                        <p
                          className="my-2"
                          style={{ color: Theme.colors.darkGrey, fontSize: 12 }}
                        >
                          by{" "}
                          <span
                            onClick={() => navigateToUserProfile()}
                            style={{
                              color: Theme.colors.blue,
                              cursor: "pointer",
                            }}
                          >
                            {item?.created_by}
                          </span>
                        </p>
                        <p
                          className="my-2"
                          style={{ color: Theme.colors.darkGrey, fontSize: 12 }}
                        >
                          {item?.updated}
                        </p>
                      </div>
                      <div className="border-bottom px-2">
                        <p className="my-2" style={{ fontSize: 12 }}>
                          {item?.title}
                        </p>
                        <p className="my-2" style={{ fontSize: 12 }}>
                          {item?.review}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-12">
              {isOpen && (
                <Lightbox
                  mainSrc={images[photoIndex]}
                  nextSrc={images[(photoIndex + 1) % images.length]}
                  prevSrc={
                    images[(photoIndex + images.length - 1) % images.length]
                  }
                  onCloseRequest={() => setIsOpen(false)}
                  onMovePrevRequest={() => changePreviousImage()}
                  onMoveNextRequest={() => changeNextImage()}
                />
              )}
            </div>
            <div className="col-12 col-md-1 d-flex flex-row justify-content-center justify-content-md-start flex-md-column p-0 pl-2">
              {images
                .filter((item, index) => index < 4)
                .map((item, index) => {
                  return (
                    <Image
                      style={{ objectFit: "cover", cursor: "pointer" }}
                      onClick={() => setPhotoIndex(index)}
                      height="70"
                      width="70"
                      key={index}
                      src={item}
                    />
                  );
                })}
            </div>
            <Image
              onClick={() => setIsOpen(!isOpen)}
              className="col-12 col-md-3 border p-0"
              style={{ objectFit: "cover", height: 100 * 4, cursor: "pointer" }}
              src={images[photoIndex]}
            />
            <div className="col-12 col-md-4">
              <div className="border-bottom">
                <h5>{t("Price")}:</h5>
                <h4 style={{ color: Theme.colors.darkGrey }}>
                  {data.price_total}$
                </h4>
              </div>
              <div className="my-3">
                <h5>{t("Description")}:</h5>
                <p>{data.description}</p>
              </div>
              <div className="my-3">
                <h5>{t("Available Specifications")}:</h5>
                <div>
                  {data?.specs?.map((item, index) => {
                    return (
                      <div key={index} className="d-flex">
                        <p className="my-0 mx-2">&#9673; {item?.name}: </p>
                        <p className="my-0 mx-2">{item?.value}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="d-flex justify-content-center justify-content-md-start align-items-center w-100">
                <Image
                  style={{ width: 100, height: 50, objectFit: "contain" }}
                  src={AteejLogo}
                />
                <p style={{ color: Theme.colors.darkGrey }}>
                  {t("Money back Guarantee")}
                </p>
              </div>
              <div className="d-flex flex-column align-items-center align-items-md-start">
                <Button
                  onClick={() => {
                    token !== null
                      ? setShowNumber(!showNumber)
                      : navigate("/login");
                  }}
                  className="w-75 py-2 rounded-0 my-1"
                  variant="primary"
                >
                  {showNumber ? profileAnyResponse?.phone : t("Buy it Now")}
                </Button>
                {/* <Button
                  onClick={() => SaveDataToLocalStorage(data)}
                  className="w-75 py-2 rounded-0 border border-primary my-1"
                  style={{ color: Theme.colors.blue }}
                  variant="light"
                >
                  {t("Add to cart")}
                </Button>
                <Button
                  className="w-75 py-2 rounded-0 border border-primary my-1"
                  style={{ color: Theme.colors.blue }}
                  variant="light"
                >
                  {t("Make Offer")}
                </Button>
                <Button
                  className="w-75 py-2 rounded-0 border border-primary my-1"
                  style={{ color: Theme.colors.blue }}
                  variant="light"
                >
                  {t("Watch")}
                </Button> */}
              </div>
              <div className="d-flex flex-column align-items-center align-items-md-start my-3">
                <p className="m-0" style={{ fontSize: 12 }}>
                  {t("Sold by")}
                </p>
                <p
                  onClick={() => navigateToProfile()}
                  className="m-0"
                  style={{
                    fontSize: 12,
                    cursor: "pointer",
                    color: Theme.colors.blue,
                  }}
                >
                  {data?.seller?.firstname} {data?.seller?.lastname}
                </p>
                <p className="m-0" style={{ fontSize: 12 }}>
                  100% {t("Positive feedback")}
                </p>
                <p
                  onClick={() => navigateToContact()}
                  className="m-0"
                  style={{
                    fontSize: 12,
                    cursor: "pointer",
                    color: Theme.colors.blue,
                  }}
                >
                  {t("Contact seller")}
                </p>
              </div>
            </div>
            <p className="col-12 mt-5" style={{ fontSize: 32 }}>
              {t("Related Sponsored items")}
            </p>
            <ScrollContainer
              hideScrollbars={false}
              style={{ backgroundColor: Theme.colors.grey }}
              vertical={false}
              horizontal={true}
              className="scroll-container"
            >
              <div className="d-flex">
                {relatedItems.length > 0 &&
                  relatedItems?.map((item) => {
                    return <Card data={item} key={item.id} />;
                  })}
              </div>
            </ScrollContainer>
            {relatedItems.length == 0 && (
              <div
                style={{ height: 200 }}
                className="card rounded w-75 mx-auto d-flex justify-content-center align-items-center"
              >
                <h6>{t("no related items founded")}</h6>
              </div>
            )}
            {added && (
              <SweetAlert
                type="success"
                title={RedNumber()}
                showConfirm={false}
              >
                <div className="d-flex justify-content-center">
                  <Button
                    onClick={() => closeAddedToCart()}
                    variant="primary mx-1"
                    size="lg"
                  >
                    {t("Go to cart")}
                  </Button>
                  <Button
                    onClick={() => closeAddedAlert()}
                    variant="success mx-1"
                    size="lg"
                  >
                    {t("Continue Shopping")}
                  </Button>
                </div>
              </SweetAlert>
            )}
            {actually && (
              <SweetAlert
                type="success"
                title={t("item already exist on the cart")}
                showConfirm={false}
              >
                <div className="d-flex justify-content-center">
                  <Button
                    onClick={() => GoToCart()}
                    variant="primary mx-1"
                    size="lg"
                  >
                    {t("Go to cart")}
                  </Button>
                  <Button
                    onClick={() => closeActuallyAlert()}
                    variant="success mx-1"
                    size="lg"
                  >
                    {t("Continue Shopping")}
                  </Button>
                </div>
              </SweetAlert>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ItemDetails;
