import React, { useState, useEffect,useContext } from "react";
import { Button } from "react-bootstrap";
import ReactLoading from 'react-loading';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { Context as MainContext } from "../../Context/mainContext";
import Card from "./component/card";
import PricesList from "./component/PricesList";
import Theme from "../../styles/theme";

const Cart = () => {
    const { state } = useContext(MainContext);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [acceptDelete, setAcceptDelete] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [arrayItems, setItemsArray] = useState([]);
    const [maxQuantity, setMaxQuantity] = useState([]);
    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const Items = JSON.parse(localStorage.getItem('itemsArray')) || [];
            const availableQuantity = JSON.parse(localStorage.getItem('availableQuantity')) || [];
            setItemsArray(Items);
            setMaxQuantity(availableQuantity);
            setIsLoading(false);
        })();
    }, [state.changeCartItems]);

    const showAcceptDeletedAlert=()=>{
        setAcceptDelete(!acceptDelete);
    }

    const showDeletedAlert=()=>{
        setAcceptDelete(false);
        setDeleted(!deleted);
    }

    const goToCheckout=()=>{
        const token = localStorage.getItem("accessToken")
            token==null?
            navigate({
                pathname: '/login',
                state: {
                    route: "/Checkout",
                }
            }):
            navigate({
            pathname: '/Checkout',
            state: {
                route: "/Checkout",
                }
            })
    }
    const totalPrice = maxQuantity.reduce((accumulator, current) => accumulator + current.price_total*current.availableQuantity, 0);
    return (
        <>
            {
                isLoading === true ? <div style={{ height: "100vh" }} className="d-flex justify-content-center align-items-center">
                    <ReactLoading type="bubbles" height={150} width={150} color={Theme.colors.darkGrey} /></div> :
                    <div className="container-fluid h-100 mb-5">
                        <div className="row">
                            <div className="col-12 col-md-7 border rounded mx-auto">
                                {
                                    arrayItems.length>0? arrayItems.map((item, index) => {
                                        return (
                                            <Card key={index} index={index} item={item} maxQuantity={maxQuantity}/>
                                        )
                                    }):<div className="d-flex h-100">
                                        <h6 className="m-auto">there is no item added</h6>
                                    </div>
                                }
                            </div>
                            <div className="col-12 col-md-4 d-flex flex-column align-items-center border rounded mx-auto h-100 py-3" style={{ backgroundColor: Theme.colors.white }}>
                                <Button onClick={() => goToCheckout()} className="rounded-0 w-75 py-2 my-3" variant="primary">{t('Go to checkout')}</Button>
                                 {
                                    maxQuantity.map((item, index) => {
                                        const price = item.availableQuantity*item.price_total;
                                        return (
                                            <PricesList key={index} index={index} data={item} price={price}/>
                                        )
                                    })
                                }
                                <div className="w-100 d-flex justify-content-between">
                                    <p style={{ fontWeight: "bold" }}>{t('Subtotal')}</p>
                                    <p style={{ fontWeight: "bold" }}>US ${totalPrice}</p> 
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default Cart;