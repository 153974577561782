import React, { useState, useContext, useEffect } from "react";
import { Form } from "react-bootstrap";
import { AiOutlineGoogle, AiFillPhone, AiOutlineMail } from "react-icons/ai";
import { useForm } from "react-hook-form";
import ReactLoading from "react-loading";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGoogleLogin } from "react-google-login";
import PhoneInput from "react-phone-input-2";

import { Context as AuthContext } from "../../Context/AuthContext";
import Theme from "../../styles/theme";
import { useApi } from "../../hooks/useFetch";
import "./login.scss";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [postLogin, loginResponse, loading] = useApi();
  const formData = new FormData();
  const { signIn } = useGoogleLogin({
    onSuccess: (response) =>
      loginwithGoogle(response.profileObj.email, response.tokenId),
    clientId:
      "845305672880-gim52p2f7mevom8jlasuqmibtocou468.apps.googleusercontent.com",
  });
  const { changeToken } = useContext(AuthContext);
  const { handleSubmit, register, errors } = useForm();
  const [loginError, setLoginError] = useState("");
  const [loginType, setLoginType] = useState("email");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const route = location.state;

  const loginwithGoogle = async (email, token) => {
    const formData = new FormData();
    formData.append("username", email);
    formData.append("uniqueid", token);
    postLogin("post", "/en/api/auth_user/login/", {}, formData, false);
  };

  const changeLoginType = (value) => {
    setLoginType(value);
  };

  const onSubmit = async (values) => {
    const { password } = values;
    if (loginType === "email") {
      formData.append("username", email);
      formData.append("password", password);
    } else {
      formData.append("username", phoneNumber);
      formData.append("password", password);
    }
    postLogin("post", "/en/api/auth_user/login/", {}, formData, false);
  };

  useEffect(() => {
    if (loginResponse?.userprofile?.id !== undefined) {
      localStorage.setItem("accessToken", loginResponse?.token?.access);
      localStorage.setItem(
        "userData",
        JSON.stringify(loginResponse?.userprofile)
      );
      localStorage.setItem("userId", loginResponse?.userprofile?.id);
      changeToken();
      route?.route == null ? navigate("/") : navigate(route?.route);
    }
  }, [loginResponse]);

  return (
    <>
      {loading === true ? (
        <div
          style={{ height: "100vh" }}
          className="d-flex justify-content-center align-items-center"
        >
          <ReactLoading
            type="bubbles"
            height={150}
            width={150}
            color={Theme.colors.darkGrey}
          />
        </div>
      ) : (
        <div className="container-fluid">
          <div className="row">
            {route?.route != null && (
              <h6 style={{ color: "red" }} className="col-12 text-center">
                You should have to login first
              </h6>
            )}
            <h1 className="col-12 text-center">{t("Hello")}</h1>
            <p className="col-12 text-center">
              {t("Sign in to Ateej or")}{" "}
              <span>
                <a href="/signup">{t("create an account")}</a>
              </span>
            </p>
            {loginError != "" && (
              <div
                class="col-3 text-center alert alert-danger mx-auto"
                role="alert"
              >
                {loginError}
              </div>
            )}
            <form
              name="form"
              className="col-12"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="col-12 col-md-3 d-flex flex-column mx-auto">
                {loginType === "email" ? (
                  <>
                    <input
                      name="email"
                      className="border-top-0 border-right-0 border-left-0 border-bottom my-2"
                      placeholder={t("Email or username")}
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                    <p style={{ color: "red", fontSize: 12 }}>
                      {errors.email && errors.email.message}
                    </p>
                  </>
                ) : (
                  <>
                    <p className="mb-3">{t("Phone Number")}</p>
                    <PhoneInput
                      buttonClass="rounded-0"
                      inputClass="w-100 rounded-0 border-top-0 border-right-0 border-left-0 border-bottom"
                      name="phoneNumber"
                      type="text"
                      country={"kw"}
                      enableAreaCodes={true}
                      areaCodes={{ us: ["332"] }}
                      inputProps={{
                        name: "phone",
                        country: "us",
                        required: true,
                        autoFocus: true,
                      }}
                      value={phoneNumber}
                      onChange={(value) => setPhoneNumber(value)}
                      required
                      inputStyle={{ backgroundColor: Theme.colors.grey }}
                    />
                  </>
                )}
                <input
                  name="password"
                  ref={register({
                    required: "Required",
                    minLength: {
                      value: 6,
                      message: "Password must have at least 6 characters",
                    },
                  })}
                  className="border-top-0 border-right-0 border-left-0 border-bottom my-2"
                  type="password"
                  placeholder={t("Password")}
                />
                <button
                  type="submit"
                  className="btn btn-primary rounded-0 my-2"
                >
                  {t("Continue")}
                </button>
                <div class="separator my-3">or</div>
                <button
                  onClick={signIn}
                  class="d-flex justify-content-between align-items-center btn btn-light rounded-0 my-2 border"
                >
                  <AiOutlineGoogle />
                  <p className="m-auto">{t("Continue with Google")}</p>
                </button>
                <button
                  onClick={() =>
                    changeLoginType(loginType === "email" ? "phone" : "email")
                  }
                  type="button"
                  class="d-flex justify-content-between align-items-center btn btn-light rounded-0 my-2 border"
                >
                  {loginType === "email" ? (
                    <>
                      <AiFillPhone />
                      <p className="m-auto">
                        {t("Continue with Phone number")}
                      </p>
                    </>
                  ) : (
                    <>
                      <AiOutlineMail />
                      <p className="m-auto">{t("Continue with email")}</p>
                    </>
                  )}
                </button>
                <Form.Group
                  className="d-flex m-auto"
                  controlId="formBasicCheckbox"
                >
                  <Form.Check
                    type="checkbox"
                    style={{ fontSize: 14 }}
                    label={t("Stay signed in")}
                  />
                </Form.Group>
                <p className="text-center my-2" style={{ fontSize: 12 }}>
                  {t("Using a public or shared device?")}
                  <br />
                  {t("Uncheck to protect your account")}.<br />
                  <a href="#">
                    <p className="text-center" style={{ fontSize: 12 }}>
                      {t("Learn more")}
                    </p>
                  </a>
                </p>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
