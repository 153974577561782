import React, { useState, useContext, useEffect, useRef } from "react";
import { AiFillFacebook, AiOutlineGoogle } from "react-icons/ai";
import { useForm } from "react-hook-form";
import axios from "axios";
import ReactLoading from "react-loading";
import { useNavigate, useLocation } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useTranslation } from "react-i18next";
import { Dropdown, FormControl, Form } from "react-bootstrap";

import {
  signinWithGoogle,
  signinWithFacebook,
  auth,
  createUserProfileDocument,
} from "../../firebase/firebase.utilts";
import { Context as AuthContext } from "../../Context/AuthContext";
import Theme from "../../styles/theme";
import "./signup.scss";

const SignUp = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const route = location.state;
  const { state, changeToken, changeAccountType } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [signupError, setSignupError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [responseError, setResponseError] = useState({});
  const [countiresData, setCountiresData] = useState([]);
  const [countryValue, setCountryValue] = useState({ title: t("all") });
  const [termsCheck, setTermsCheck] = useState(false);
  var bodyFormData = new FormData();
  const { handleSubmit, register, errors } = useForm();

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      try {
        await axios({
          method: `get`,
          url: `http://api.ateej.net/en/api/countries/`,
        })
          .then((res) => {
            setCountiresData(res.data?.context);
          })
          .catch((error) => {
            const errorToSend = error?.response?.statusText?.replace(
              / /g,
              "_"
            )(async () => {
              try {
                await axios({
                  method: "post",
                  url: `http://api.ateej.net/en/api/logs/web/${errorToSend}`,
                })
                  .then((res) => {
                    console.log(res);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
                setIsLoading(false);
              } catch (error) {
                setIsLoading(false);
                console.log(error);
              }
            })();
          });
      } catch (error) {
        console.log(error);
      }
    })();
    auth.onAuthStateChanged((user) => {
      createUserProfileDocument(user);
    });
    setIsLoading(false);
  }, []);

  const onSubmit = async (values) => {
    bodyFormData.append("email", values?.email);
    bodyFormData.append("password", values?.password);
    bodyFormData.append("cpassword", values?.password);
    bodyFormData.append("phone", phoneNumber);
    bodyFormData.append("country", countryValue?.id);
    bodyFormData.append("images", values?.picture[0]);
    bodyFormData.append("firstname", values?.firstname);
    bodyFormData.append("lastname", values?.lastname);
    bodyFormData.append("user_type", state?.accountType);
    bodyFormData.append("legal_business_name", values?.legalBusinessName);
    bodyFormData.append("concent", termsCheck);
    localStorage.setItem("email", values.email);
    localStorage.setItem("phone", phoneNumber);
    try {
      setIsLoading(true);
      await axios({
        method: "post",
        url: "http://api.ateej.net/en/api/auth_user/register/",
        data: bodyFormData,
      })
        .then((res) => {
          setIsLoading(false);
          const response = res.data;
          localStorage.setItem("accessToken", response.context.token.access);
          localStorage.setItem("userId", response?.context?.userprofile?.id);
          changeToken();
          route?.route == null
            ? navigate("/VerifyBy")
            : navigate(route?.route);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log("error?.response", error?.response?.status);
          error?.response?.status == 400 &&
            setSignupError(t("missing some input please fill all inputs"));
          error?.response?.status == 409 &&
            setSignupError(t("username actually exists, try another username"));
          error?.response?.status == 406 &&
            setSignupError(
              t("username contains special characters no acceptable")
            );
          error?.response?.status == 428 &&
            setSignupError(t("missing some inputs please fill all text feild"));
          setIsLoading(true);
          const errorToSend = error?.response?.statusText?.replace(
            / /g,
            "_"
          )(async () => {
            try {
              await axios({
                method: "post",
                url: `http://api.ateej.net/en/api/logs/web/${errorToSend}`,
              })
                .then((res) => {
                  const errorCatched = res.data;
                  setResponseError(errorCatched);
                })
                .catch((error) => {
                  console.log(error);
                });
              setIsLoading(false);
            } catch (error) {
              setIsLoading(false);
              console.log(error);
            }
          })();
          setIsLoading(false);
        });
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  const AccountsType = [
    {
      id: 1,
      title: `${t("Personal Account")}`,
    },
    {
      id: 2,
      title: `${t("Business Account")}`,
    },
  ];
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <p
      className="d-flex justify-content-between align-items-center px-3 py-1 rounded-0 border-bottom w-100"
      style={{ cursor: "pointer", backgroundColor: Theme.colors.grey }}
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <span className="ml-2" style={{ fontSize: 12 }}>
        &#x25bc;
      </span>
    </p>
  ));

  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      const [value, setValue] = useState("");

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <FormControl
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder={t("Type to filter Countries")}
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );
  const TermsofUse = () => {
    return (
      <span>
        {t("i have read and agree to the")}{" "}
        <a
          style={{ cursor: "pointer", color: Theme.colors.blue }}
          onClick={() =>
            window.open(
              "https://www.privacypolicies.com/live/41f5a86a-958e-45eb-813e-4f802ef39897"
            )
          }
        >
          {t("Terms of Use")}
        </a>{" "}
        {t("and")}{" "}
        <a
          style={{ cursor: "pointer", color: Theme.colors.blue }}
          onClick={() =>
            window.open(
              "https://www.privacypolicies.com/live/41f5a86a-958e-45eb-813e-4f802ef39897"
            )
          }
        >
          {t("Privacy Policy")}
        </a>
        .
      </span>
    );
  };
  const toggleBusinessAccount = () => {
    changeAccountType(2);
    navigateToForm();
  };
  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
  const formBody = useRef(null);
  const navigateToForm = () => scrollToRef(formBody);
  console.log({ countiresData });
  return (
    <>
      {isLoading === true ? (
        <div
          style={{ height: "100vh" }}
          className="d-flex justify-content-center align-items-center"
        >
          <ReactLoading
            type="bubbles"
            height={150}
            width={150}
            color={Theme.colors.darkGrey}
          />
        </div>
      ) : (
        <div className="container">
          <div className="row">
            <h1 className="col-12 text-center">{t("create an account")}</h1>
            <div className="col-12 d-flex justify-content-center align-items-center mx-auto">
              <p>{t("Have a business?")}</p>
              <p
                className="mx-1"
                onClick={() => toggleBusinessAccount()}
                style={{ color: Theme.colors.blue, cursor: "pointer" }}
              >
                {t("Create a business account")}
              </p>
            </div>
            <div className="col-12 col-md-6 my-auto">
              <div className="w-75 mx-auto">
                <h5 className="my-5">
                  {t("Register with your email and phone number")}
                </h5>
                <div className="d-flex justify-content-around">
                  {AccountsType?.map((item, index) => {
                    const toggleAccountType = (value) => {
                      changeAccountType(value);
                    };
                    return (
                      <p
                        key={item?.id}
                        onClick={() => toggleAccountType(item?.id)}
                        style={{
                          backgroundColor: Theme.colors.grey,
                          borderBottomColor:
                            state?.accountType === item?.id
                              ? Theme.colors.darkGrey
                              : Theme.colors.grey,
                          borderBottomWidth: 2,
                          borderBottomStyle: "solid",
                          cursor: "pointer",
                        }}
                        className="py-1 px-3 rounded-top"
                      >
                        {item?.title}
                      </p>
                    );
                  })}
                </div>
                {signupError != "" && (
                  <div
                    class="col-12 text-center alert alert-danger mx-auto"
                    role="alert"
                  >
                    {signupError}
                  </div>
                )}
                <form
                  ref={formBody}
                  name="form"
                  className="col-12"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="d-flex flex-column my-3 w-100">
                    <p className="my-0">{t("firstName")}</p>
                    <input
                      name="firstname"
                      ref={register({
                        required: "Required",
                      })}
                      className="border-top-0 border-right-0 border-left-0 border-bottom col-12 my-3"
                      placeholder={t("firstName")}
                      style={{ backgroundColor: Theme.colors.grey }}
                    />
                    <p style={{ color: "red", fontSize: 12 }}>
                      {errors.firstname && errors.firstname.message}
                    </p>
                    <p className="my-0">{t("lastName")}</p>
                    <input
                      name="lastname"
                      ref={register({
                        required: "Required",
                      })}
                      className="border-top-0 border-right-0 border-left-0 border-bottom col-12 my-3"
                      placeholder={t("lastName")}
                      style={{ backgroundColor: Theme.colors.grey }}
                    />
                    <p style={{ color: "red", fontSize: 12 }}>
                      {errors.lastname && errors.lastname.message}
                    </p>
                    <p className="my-0">{t("Email")}</p>
                    <input
                      name="email"
                      ref={register({
                        required: "Required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "invalid email address",
                        },
                      })}
                      className="border-top-0 border-right-0 border-left-0 border-bottom col-12 my-3"
                      placeholder={t("Email")}
                      style={{ backgroundColor: Theme.colors.grey }}
                    />
                    <p style={{ color: "red", fontSize: 12 }}>
                      {errors.email && errors.email.message}
                    </p>
                    <p className="my-0">{t("Password")}</p>
                    <input
                      name="password"
                      ref={register({
                        required: "Required",
                        minLength: {
                          value: 6,
                          message: "Password must have at least 6 characters",
                        },
                      })}
                      className="border-top-0 border-right-0 border-left-0 border-bottom col-12 my-3 w-100"
                      type="password"
                      placeholder={t("Password")}
                      style={{ backgroundColor: Theme.colors.grey }}
                    />
                    <p style={{ color: "red", fontSize: 12 }}>
                      {errors.password && errors.password.message}
                    </p>
                  </div>
                  <p className="mb-3">{t("Phone Number")}</p>
                  <PhoneInput
                    buttonClass="rounded-0"
                    inputClass="w-100 rounded-0 border-top-0 border-right-0 border-left-0 border-bottom"
                    name="phoneNumber"
                    type="text"
                    country={"kw"}
                    enableAreaCodes={true}
                    areaCodes={{ us: ["332"] }}
                    inputProps={{
                      name: "phone",
                      country: "us",
                      required: true,
                      autoFocus: true,
                    }}
                    value={phoneNumber}
                    onChange={(value) => setPhoneNumber(value)}
                    required
                    inputStyle={{ backgroundColor: Theme.colors.grey }}
                  />
                  <p className="my-0">{t("Select country")}</p>
                  {countiresData.length > 0 && (
                    <Dropdown className="w-100 my-3">
                      <Dropdown.Toggle
                        as={CustomToggle}
                        id="dropdown-custom-components"
                      >
                        {countryValue?.title}
                      </Dropdown.Toggle>
                      <Dropdown.Menu as={CustomMenu}>
                        <div
                          style={{
                            maxHeight: 150,
                            overflowY: "auto",
                          }}
                        >
                          {countiresData?.map((item, index) => {
                            return (
                              <Dropdown.Item
                                onClick={() => setCountryValue(item)}
                                key={item?.id}
                                eventKey={1}
                              >
                                {item?.title}
                              </Dropdown.Item>
                            );
                          })}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                  <p className="my-0">{t("Upload Image")}</p>
                  <input
                    className="my-3 w-100"
                    ref={register}
                    type="file"
                    name="picture"
                    style={{ backgroundColor: Theme.colors.grey }}
                  />
                  {state?.accountType === 2 && (
                    <>
                      <p className="my-0">{t("legal business name")}</p>
                      <input
                        ref={register}
                        name="legal_business_name"
                        className="border-top-0 border-right-0 border-left-0 border-bottom col-12 my-3 w-100"
                        type="text"
                        placeholder={t("legal business name")}
                        style={{ backgroundColor: Theme.colors.grey }}
                      />
                      <p style={{ color: "red", fontSize: 12 }}>
                        {errors.legal_business_name &&
                          errors.legal_business_name.message}
                      </p>
                    </>
                  )}
                  <Form.Group className="my-3" controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      checked={termsCheck}
                      onClick={() => setTermsCheck(!termsCheck)}
                      label={TermsofUse()}
                    />
                  </Form.Group>
                  <button
                    type="submit"
                    disabled={!termsCheck}
                    className="btn btn-primary w-100 rounded-0 my-2"
                  >
                    {t("Create account")}
                  </button>
                  <div className="d-flex justify-content-center align-items-center">
                    <p>{t("Already a member?")}</p>
                    <a href="/login" className="mx-1">
                      <p>{t("Sign in")}</p>
                    </a>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="w-75 mx-auto">
                <h5 className="my-5">
                  {t("Register with your social media account")}
                </h5>
                <button
                  onClick={signinWithFacebook}
                  class="w-100 d-flex justify-content-between align-items-center btn btn-primary rounded-0 my-4"
                >
                  <AiFillFacebook />
                  <p className="m-auto">{t("Continue with Facebook")}</p>
                </button>
                <button
                  onClick={signinWithGoogle}
                  class="w-100 d-flex justify-content-between align-items-center btn btn-light rounded-0 my-4 border"
                >
                  <AiOutlineGoogle />
                  <p className="m-auto">{t("Continue with Google")}</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SignUp;
