import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Image, Dropdown } from "react-bootstrap";
import Theme from "../../styles/theme";
import ReactLoading from "react-loading";

const CheckoutItem = () => {
  const location = useLocation();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const incommingdata = location.state;
        setData(incommingdata);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log("error in home data:", error);
        alert(error);
      }
    })();
  }, []);
  const addQuantityItems = (item) => {
    const dataToEdit = data;
    // dataToEdit?.item?.quantity=item;
    console.log("dataToEdit", data);
    // setQuantity(item);
    // const findingIndex = dataBeforeDelete.findIndex((item,index)=>index==incommingIndex)
    // dataBeforeDelete[findingIndex]={...dataBeforeDelete[findingIndex],quantity:item}
    // console.log("dataBeforeDelete[findingIndex]",dataBeforeDelete[findingIndex]);
    // localStorage.setItem('itemsArray', JSON.stringify(dataBeforeDelete));
    // setQuantitySelected(!quantitySelected);
  };
  // console.log("data",data?.item?.quantity);

  return (
    <>
      {isLoading === true ? (
        <div
          style={{ height: "100vh" }}
          className="d-flex justify-content-center align-items-center"
        >
          <ReactLoading
            type="bubbles"
            height={150}
            width={150}
            color={Theme.colors.darkGrey}
          />
        </div>
      ) : (
        <div className="container-fluid h-100 mb-5">
          <div className="row">
            <div
              className="p-3"
              style={{ backgroundColor: Theme.colors.lightGrey }}
            >
              <h3>{data?.item?.title}</h3>
              <div className="d-flex">
                <Image width="200" height="170" src={data?.item?.image[0]} />
                <div className="p-3">
                  <p className="m-0">
                    item price: <b>US ${data?.item?.price_total}</b>
                  </p>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="primary rounded-0"
                      id="dropdown-basic"
                    >
                      {data?.item?.quantity}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {numbers.map((item, index) => {
                        return (
                          <Dropdown.Item
                            onClick={() => addQuantityItems(item)}
                            key={index}
                            href="#/action-1"
                          >
                            {item}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CheckoutItem;
