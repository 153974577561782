import React, { useEffect, useState } from "react";
import Theme from "../../styles/theme";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import GooglePlay from "../../assets/images/googlePlay.png";
import AppStore from "../../assets/images/appStore.png";
import AteejLogo from "../../assets/images/AteejLogo.png";

const FooterContainer = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [categoryData, setCategoryData] = useState([]);

  const navigateToCAtegoryDetails = (value) => {
    navigate(`/${value}`);
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        await axios({
          method: "get",
          url: "http://api.ateej.net/en/api/items/menu/bottom/1",
        })
          .then((res) => {
            setIsLoading(false);
            const response = res.data.context;
            setCategoryData(response);
          })
          .catch((error) => {
            setIsLoading(true);
            const errorToSend = error?.response?.statusText.replace(/ /g, "_");
            error.response.status == 404 &&
              navigate("/NotFound")(async () => {
                try {
                  await axios({
                    method: "post",
                    url: `http://api.ateej.net/en/api/logs/web/${errorToSend}`,
                  })
                    .then((res) => {
                      console.log(res);
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                  setIsLoading(false);
                } catch (error) {
                  setIsLoading(false);
                  console.log(error);
                }
              })();
          });
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <div className="container-fluid">
      <div className="row border-top py-5">
        {categoryData.map((item, index) => {
          return (
            <a
              className="mx-2"
              key={item.id}
              onClick={() => navigateToCAtegoryDetails(item.root)}
              style={{
                color: Theme.colors.darkGrey,
                fontSize: 12,
                cursor: "pointer",
              }}
            >
              {item.title}
            </a>
          );
        })}
      </div>
      <div className="row">
        <div className="d-flex flex-column flex-md-row align-items-center w-75 justify-content-between mx-auto">
          <img
            style={{ cursor: "pointer" }}
            src={AteejLogo}
            alt="logo"
            width="auto"
            height="100"
          />
          <div>
            <a href="https://play.google.com/store/apps/details?id=com.polarbeargroup.ateej">
              <img
                style={{ cursor: "pointer" }}
                src={GooglePlay}
                alt="logo"
                width="auto"
                height="70"
                className="mx-3 mx-md-1 rounded mb-1 mt-3 mt-md-1"
              />
            </a>
            <a href="https://apps.apple.com/us/app/ateej/id1572703161">
              <img
                style={{ cursor: "pointer" }}
                src={AppStore}
                alt="logo"
                width="auto"
                height="70"
                className="mx-3 mx-md-1 rounded my-1"
              />
            </a>
          </div>
        </div>
      </div>
      <p style={{ fontSize: 12, color: Theme.colors.darkGrey }}>
        {t("Copyright")}.
        <span>
          <a href="#" style={{ color: "inherit" }}>
            {" "}
            {t("Accessibility")}
          </a>
          ,
        </span>
        <span>
          <a href="#" style={{ color: "inherit" }}>
            {" "}
            {t("User Agreement")}
          </a>
          ,
        </span>
        <span>
          <a href="#" style={{ color: "inherit" }}>
            {" "}
            {t("Privacy")}
          </a>
          ,
        </span>
        <span>
          <a href="#" style={{ color: "inherit" }}>
            {" "}
            {t("Cookies")}
          </a>
          ,
        </span>
        <span>
          <a href="#" style={{ color: "inherit" }}>
            {" "}
            {t("Do not sell my personal information")}
          </a>
          ,
        </span>
        <span> {t("and")}</span>
        <span>
          <a href="#" style={{ color: "inherit" }}>
            {" "}
            {t("AdChoice")}
          </a>
          ,
        </span>
      </p>
    </div>
  );
};
export default FooterContainer;
