import React from "react";
import { FaBox } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import { Dropdown, Image } from "react-bootstrap";

import Card from "../Card/Card";
import Product from "../../staticData/ProductData";
import Theme from "../../styles/theme";
import "./ProductContainer.scss";

const ProductContainer = ({ data, banners }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const navigateToCAtegoryDetails = (value) => {
    navigate(`/${value}`);
  };
  const openExternalLink = () => {
    window.open(banners[0]?.external_link, banners[0]?.target);
  };
  const bannersTypeOne = banners.filter((item) => item?.banner_type === "1");
  const bannersTypeOneThemeOne = bannersTypeOne.filter(
    (item) => item?.theme === "1"
  );
  const bannersTypeTwo = banners.filter((item) => item?.banner_type === "2");

  return (
    <div className="container-fluid">
      {data?.map((item, index) => {
        return (
          <div key={item.id}>
            <div className=" my-3">
              <div className="row">
                <h2
                  onClick={() => navigateToCAtegoryDetails(item.root)}
                  className="pr-4 border-right"
                  style={{ cursor: "pointer" }}
                >
                  {item.root}
                </h2>
                <p
                  onClick={() => navigateToCAtegoryDetails(item.root)}
                  className="mx-4"
                  style={{ fontSize: 24, cursor: "pointer" }}
                >
                  {t("See all")}
                  <span className="mx-2" style={{ fontSize: 28 }}>
                    &#8594;
                  </span>
                </p>
              </div>
              <p style={{ color: "#767676", fontSize: 24 }}>
                {t("Recommended for you")}
              </p>
            </div>
            <ScrollContainer
              hideScrollbars={false}
              vertical={false}
              horizontal={true}
              className="scroll-container"
            >
              <div className="d-flex p-3 rounded">
                {item?.items
                  ?.filter((_, i) => i <= 6)
                  .map((subItems) => {
                    return (
                      <div
                        key={subItems.id}
                        className="d-flex justify-content-center align-items-center mx-4 my-3"
                      >
                        <Card
                          category_id={item.category_id}
                          data={subItems}
                          listIndex={index}
                          haveDiscount={false}
                        />
                      </div>
                    );
                  })}
              </div>
            </ScrollContainer>
            {1 > index > 0 &&
              bannersTypeOneThemeOne?.map((item, index) => {
                return (
                  <div className="my-5" key={item?.id}>
                    {item?.theme === "1" ? (
                      <div
                        className="row my-5"
                        style={{ backgroundColor: Theme.colors.darkBlue }}
                      >
                        <div
                          className="row col-12 col-md-8 d-flex justify-content-center align-items-start px-2"
                          style={{ alignSelf: "center" }}
                        >
                          <div
                            style={{ cursor: "pointer" }}
                            className="col-2 d-none d-md-flex my-auto justify-content-center align-items-center"
                          >
                            <Image
                              src={item?.image}
                              style={{
                                width: 80,
                                height: 80,
                                objectFit: "contain",
                              }}
                            />
                            {/* <FaBox size={48} color={Theme.colors.white} /> */}
                          </div>
                          <div className="col-10 p-3">
                            <h1
                              style={{
                                color: Theme.colors.white,
                              }}
                            >
                              {item?.title}
                            </h1>
                            <p
                              style={{
                                color: Theme.colors.white,
                              }}
                            >
                              {item?.description}
                            </p>
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex justify-content-center align-items-center">
                          <button
                            onClick={() =>
                              window.open(item?.external_link, item?.target)
                            }
                            class="button"
                          >
                            <span>{item?.external_link_name}</span>
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                );
              })}
            {index == 1 && (
              <div className="row my-5">
                <div
                  className="col-12 col-md-3 d-flex flex-column justify-content-center align-items-center px-2"
                  style={{
                    height: 330,
                    alignSelf: "center",
                    backgroundColor: Theme.colors.extraDarkBlue,
                  }}
                >
                  <h2
                    style={{
                      color: Theme.colors.white,
                    }}
                  >
                    {Product.discountProduct.title}
                  </h2>
                  <p
                    style={{
                      color: Theme.colors.white,
                    }}
                  >
                    {Product.discountProduct.description}
                  </p>
                  <button class="button">
                    <span>{t("Shop Now")}</span>
                  </button>
                </div>
                <div className="col-12 col-md-9 p-2 mt-n5 mt-md-0 d-flex">
                  <ScrollContainer
                    hideScrollbars={false}
                    vertical={false}
                    horizontal={true}
                    className="scroll-container my-auto"
                  >
                    {bannersTypeTwo?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="d-flex justify-content-center align-items-center rounded"
                        >
                          {item?.theme === "1"
                            ? item?.items?.map((subItems, subIndex) => {
                                console.log("subItems", subItems);
                                return (
                                  <div key={subIndex}>
                                    <Card
                                      index={subIndex}
                                      haveDiscount={true}
                                      discount={item?.price_total}
                                      name={item.title}
                                      price={item?.price_total}
                                      data={subItems}
                                      imageUrl={item?.thumbnail}
                                    />
                                  </div>
                                );
                              })
                            : null}
                        </div>
                      );
                    })}
                  </ScrollContainer>
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ProductContainer;
