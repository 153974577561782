import React, { useContext } from "react";
import { Dropdown } from "react-bootstrap";
import { IoMdArrowDropdown } from "react-icons/io";
import ScrollContainer from "react-indiana-drag-scroll";
import { useNavigate } from "react-router-dom";

import { useWindowSize } from "../../../../../hooks/useWindowSize";
import { Context as mainContext } from "../../../../../Context/mainContext";
import Card from "./component/Card";
import "./DropDown.scss";

const DropDown = ({ Categories, title, type = "verticalList" }) => {
  const { width } = useWindowSize();
  const { push } = useNavigate();
  const { changeSubCategoryId, changeSubCategoryName } = useContext(
    mainContext
  );
  const toggleToCategoryDetails = (data) => {
    const categoryTitle = data?.title?.replace(/ /g, "_");
    const roottitle = title?.replace(/ /g, "_");
    push({
      pathname: `/${roottitle}/${categoryTitle}`,
      state: {
        _subCategoryId: data?.id,
      },
    });
    changeSubCategoryId(data?.id);
    changeSubCategoryName(data?.title);
  };

  return (
    <Dropdown className="d-flex">
      <Dropdown.Toggle
        variant="light"
        id="dropdown-basic"
        style={{ fontSize: 12 }}
      >
        {title && (
          <div className="dropbtn">
            {title}
            <IoMdArrowDropdown className="mx-1" size={15} />
          </div>
        )}
      </Dropdown.Toggle>
      <div style={{ overflowBlock: "hidden" }}>
        {Categories?.length && Categories !== undefined && (
          <Dropdown.Menu className="d-flex">
            <ScrollContainer
              className={
                type === "horizentalList" ? "d-flex flex-column" : "d-flex"
              }
              style={{ maxWidth: width * 0.8 }}
            >
              {Categories?.length &&
                Categories !== undefined &&
                Categories?.map((item, index) => {
                  return (
                    <table key={item?.id}>
                      <tr>
                        <Dropdown.Item className="drop-down-item">
                          {type === "horizentalList" && (
                            <p
                              onClick={() => toggleToCategoryDetails(item)}
                              style={{ textAlign: "center" }}
                              className="m-0 p-0"
                            >
                              {item?.title}
                            </p>
                          )}
                          <td>
                            <ul
                              className={
                                item.items !== null
                                  ? "link-format d-flex flex-column justify-content-start align-items-start p-2"
                                  : ""
                              }
                            >
                              {type === "verticalList" && (
                                <p
                                  style={{ textAlign: "center" }}
                                  className="m-0 p-0"
                                >
                                  {item?.title}
                                </p>
                              )}
                              {item?.childs?.length &&
                                type === "verticalList" &&
                                item?.childs.map((subItems) => {
                                  return (
                                    <Card
                                      key={item?.id}
                                      rootTitle={item.title}
                                      data={subItems}
                                    />
                                  );
                                })}
                            </ul>
                          </td>
                        </Dropdown.Item>
                      </tr>
                    </table>
                  );
                })}
            </ScrollContainer>
          </Dropdown.Menu>
        )}
      </div>
    </Dropdown>
  );
};
export default DropDown;
