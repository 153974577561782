import React, { useEffect, useState, useContext } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Image, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

import { useWindowSize } from "../../../../hooks/useWindowSize";
import { Context as MainContext } from "../../../../Context/mainContext";
import Theme from "../../../../styles/theme";

const Card = ({ item, index }) => {
  const { width, height } = useWindowSize();
  const { changeCartItems, changeItemId } = useContext(MainContext);
  const { t, i18n } = useTranslation();
  const [acceptDelete, setAcceptDelete] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [errorDeleted, setErrorDeleted] = useState(false);
  const [dataBeforeDelete, setDataBeforeDelete] = useState([]);
  const [incommingIndex, setIndex] = useState(null);
  const navigate = useNavigate();
  const token = localStorage.getItem("accessToken");
  const showAcceptDeletedAlert = () => {
    setAcceptDelete(!acceptDelete);
  };

  const showDeletedAlert = () => {
    setAcceptDelete(false);
    setDeleted(!deleted);
  };

  const closeDeletedAlert = () => {
    setDeleted(false);
    changeCartItems();
  };
  const rootTitle = item?.root?.title?.replace(/ /g, "_");
  const categoryTitle = item?.category?.title?.replace(/ /g, "_");
  const navigateToItemDetails = () => {
    navigate({
      pathname: `/${rootTitle}/${categoryTitle}/${item?.id}`,
      state: {
        _id: item?.id,
      },
    });
    changeItemId(item?.id);
  };

  const DeleteItem = async () => {
    await axios({
      method: "delete",
      url: `http://api.ateej.net/en/api/items/manage/${item.id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        console.log(res);
        showDeletedAlert();
      })
      .catch((error) => {
        setErrorDeleted(true);
        console.log(error);
      });
  };

  return (
    <div
      className="d-flex justify-content-between px-3 border"
      //   style={{ minWidth: width * 0.3 }}
    >
      <div>
        <div className="d-flex">
          <Image
            onClick={() => navigateToItemDetails()}
            style={{ cursor: "pointer", objectFit: "cover" }}
            className="my-2"
            width={width * 0.1}
            height={width * 0.1}
            src={item.thumbnail}
          />
          <div className="d-flex flex-column justify-content-between">
            <div className="d-flex">
              <p
                className="ml-3"
                style={{
                  fontSize: 15,
                  fontWeight: "bold",
                  width: width * 0.1,
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {item?.title}
              </p>
              <p
                className="ml-3"
                style={{
                  fontSize: 15,
                  fontWeight: "bold",
                  width: width * 0.06,
                }}
              >
                {item?.price_total}$ US
              </p>
            </div>
            <div className="d-flex justify-content-end align-items-end">
              <p
                className="my-0"
                onClick={() => DeleteItem()}
                style={{
                  fontSize: 30,
                  color: Theme.colors.darkGrey,
                  cursor: "pointer",
                }}
              >
                ×
              </p>
            </div>
          </div>
        </div>
      </div>
      {acceptDelete && (
        <SweetAlert
          title="Are you sure want to delete this item?"
          onConfirm={showDeletedAlert}
          onCancel={showAcceptDeletedAlert}
          showCancel={true}
          focusCancelBtn={true}
        />
      )}
      {deleted && (
        <SweetAlert
          type="success"
          title="Item deleted successful"
          onConfirm={closeDeletedAlert}
          focusCancelBtn={true}
        />
      )}
      {errorDeleted && (
        <SweetAlert
          type="error"
          title="Error with deleting item"
          onConfirm={() => setErrorDeleted(false)}
          focusCancelBtn={true}
        />
      )}
    </div>
  );
};

export default Card;
