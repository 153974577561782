import React from "react";
import { Provider as AuthProvider } from "./Context/AuthContext";
import { Provider as HomeProvider } from "./Context/mainContext";
import { Route, Routes } from "react-router-dom";

import "./App.css";

import Home from "./pages/home";
import Login from "./pages/login/login";
import SignUp from "./pages/signup/signup";
import VerifyBy from "./pages/VerifyBy";
import Verification from "./pages/Verification";
import Category from "./pages/Category";
import Brands from "./pages/Brands";
import BrandsDetails from "./pages/BrandsDetails";
import ItemDetails from "./pages/itemDetails/itemDetails";
import RatingAndReviews from "./pages/RatingAndReviews";
import Cart from "./pages/cart/cart";
import Notifications from "./pages/notifications/Notifications";
import Checkout from "./pages/checkout/checkout";
import CheckoutItem from "./pages/checkoutItem/CheckoutItem";
import Profile from "./pages/profile/Profile";
import {DeleteAccount} from "./pages/deleteProfile";
import Search from "./pages/Search/Search";
import Contact from "./pages/Contact/Contact";
import NotFound from "./pages/NotFound";
import ProfileToVisit from "./pages/ProfileToVisit";

import MainHeader from "./component/headerContainer/mainHeader";
import SearchContainer from "./component/headerContainer/SearchContainer";
import FooterContainer from "./component/FooterContainer/FooterContainer";

import AteejLogo from "./assets/images/AteejLogo.png";

const App = () => {
  return (
    <HomeProvider>
      <AuthProvider>
        <div className="container-fluid h-100">
          <div className="row">
            <div className="col-12">
              <MainHeader />
              <SearchContainer img={AteejLogo} />
            </div>
          </div>
        </div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/VerifyBy" element={<VerifyBy />} />
          <Route path="/Verification" element={<Verification />} />
          <Route path="/Brands" element={<Brands />} />
          <Route path="/Brands/:brandName" element={<BrandsDetails />} />
          <Route path="/Cart" element={<Cart />} />
          <Route path="/Notifications" element={<Notifications />} />
          <Route path="/Checkout" element={<Checkout />} />
          <Route path="/CheckoutItem" element={<CheckoutItem />} />
          <Route path="/Profile/:username" element={<Profile />} />
          <Route path="/Delete-profile/" element={<DeleteAccount />} />
          <Route path="/Search" element={<Search />} />
          <Route path="/Contact/:username" element={<Contact />} />
          <Route path="/User/:username" element={<ProfileToVisit />} />
          <Route
            path="/:categoryName/:subcategoryName"
            element={<Category />}
          />
          <Route path="/:categoryName" element={<Category />} />
          <Route
            path="/:categoryName/:subcategoryName/:itemId"
            element={<ItemDetails />}
          />
          <Route
            path="/:categoryName/:subcategoryName/:itemId/Reviews"
            element={<RatingAndReviews />}
          />
          <Route path="/NotFound" element={<NotFound />} />
          <Route path="*" element={<NotFound />} />{" "}
          {/* Handle unmatched routes */}
        </Routes>
        <FooterContainer />
      </AuthProvider>
    </HomeProvider>
  );
};

export default App;
