import React, { useState, useEffect, useContext } from "react";
import { Button, Modal, Spinner, Alert } from "react-bootstrap";

import { Context as AuthContext } from "../../Context/AuthContext";
import { useApi } from "../../hooks/useFetch";
import { useNavigate } from "react-router-dom";
import { logoutUtl } from "../../utilts";

const DeleteAccount = () => {
  const {
    state: { accountData: userData },
    changeToken,
  } = useContext(AuthContext);
  const navigate = useNavigate();

  const [deleteAccount, _, deleteAccountLoading] = useApi(
    (response) => {
      if (response.status === 205) {
        setIsRemoved(true);
      } else {
        setError("Failed to delete account. Please try again.");
      }
    },
    (err) => {
      console.error("Error deleting account:", err);
      setError("Something went wrong while deleting your account.");
    }
  );

  const [langID, setLangID] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isRemoved, setIsRemoved] = useState(false);
  const [error, setError] = useState(null);

  const fetchLangID = async () => {
    try {
      const storedLangID = localStorage.getItem("langID"); // Using localStorage as AsyncStorage equivalent
      setLangID(storedLangID || "en");
    } catch (err) {
      console.error("Error fetching langID:", err);
    }
  };

  useEffect(() => {
    fetchLangID();
  }, []);

  const logout = () => {
    changeToken();
    navigate("/");
    window.location.reload();
  };

  const deleteAccountHandler = async (status) => {
    setShowModal(false);
    const formData = new FormData();
    formData.append("email", userData?.email);
    deleteAccount(
      "put",
      `/${langID}/api/auth_user/profile/status/${status}`,
      {},
      formData
    );
  };

  return (
    <div>
      {/* Delete Button */}
      <Button
        variant="outline-danger"
        onClick={() => setShowModal(true)}
        className="mt-3"
      >
        <i className="fas fa-trash"></i> Delete Account
      </Button>

      {/* Confirmation Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Account Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete your account? This action cannot be
          undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => deleteAccountHandler(1)}
            disabled={deleteAccountLoading}
          >
            {deleteAccountLoading ? (
              <Spinner size="sm" animation="border" />
            ) : (
              "Delete"
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Success Alert */}
      {isRemoved && (
        <Alert
          variant="success"
          onClose={() => {
            setIsRemoved(false);
            logoutUtl();
            logout();
          }}
          dismissible
          className="mt-3"
        >
          Your account has been successfully deleted.
        </Alert>
      )}

      {/* Error Alert */}
      {error && (
        <Alert
          variant="danger"
          onClose={() => setError(null)}
          dismissible
          className="mt-3"
        >
          {error}
        </Alert>
      )}
    </div>
  );
};

export default DeleteAccount;
