import createDataContext from "./createDataContext";
import { LoginRequest } from "../services/AuthServices";

const initalState = {
  token: false,
  changeCartItems: false,
  verifyType: "",
  verifyId: 1,
  accountType: 1,
  accountData: {},
};

const authReducer = (state = initalState, action) => {
  switch (action.type) {
    case "CHANGE_TOKEN":
      return { ...state, token: state.token == true ? false : true };
    case "CHANGE_CART_ITEMS":
      return { ...state, changeCartItems: !state.changeCartItems };
    case "CHANGE_VERIFY_TYPE":
      return { ...state, verifyType: action.payload };
    case "CHANGE_VERIFY_ID":
      return { ...state, verifyId: action.payload };
    case "CHANGE_ACCOUNT_TYPE":
      return { ...state, accountType: action.payload };
    case "CHANGE_USER_DATA":
      return { ...state, accountData: action.payload };
    default:
      return state;
  }
};

const changeToken = (dispatch) => async () => {
  dispatch({ type: "CHANGE_TOKEN" });
};

const changeAccountData = (dispatch) => async () => {
  dispatch({ type: "CHANGE_USER_DATA" });
};

const changeCartItemsAuthContext = (dispatch) => async () => {
  dispatch({ type: "CHANGE_CART_ITEMS" });
};

const changeVerifyType = (dispatch) => async (data) => {
  dispatch({ type: "CHANGE_VERIFY_TYPE", payload: data });
};

const changeVerifyId = (dispatch) => async (data) => {
  dispatch({ type: "CHANGE_VERIFY_ID", payload: data });
};

const changeAccountType = (dispatch) => async (data) => {
  dispatch({ type: "CHANGE_ACCOUNT_TYPE", payload: data });
};

export const { Provider, Context } = createDataContext(
  authReducer,
  {
    changeToken,
    changeAccountData,
    changeCartItemsAuthContext,
    changeVerifyType,
    changeVerifyId,
    changeAccountType,
  },
  initalState
);
