import { useState } from "react";
import Axios from "axios";
import { API_STAGING } from "../Constants";

export const useApi = (onSuccess = () => {}, onFail = () => {}) => {
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState(null);
  const token = localStorage.getItem("accessToken");

  const fetchData = async (
    method = "get",
    url,
    params,
    data,
    haveToken = true
  ) => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      setLoading(true);
      const res = await Axios({
        method,
        url: API_STAGING + url,
        headers: haveToken ? headers : {},
        data: data || {},
        params: params || {},
      });
      setLoading(false);
      setResponse(res.data?.context);
      setResponseStatus(res.status);
      onSuccess(res);
    } catch (error) {
      onFail(error);
      console.log({ error });
      setLoading(false);
      setResponse(error);
      const errorToSend = error?.response?.statusText?.replace(/ /g, "_");
      await Axios({
        method: "post",
        url: API_STAGING + `/en/api/logs/web/${errorToSend}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
  };
  return [fetchData, response, loading, responseStatus];
};
