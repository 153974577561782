import React,{useContext} from "react";
import { Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import Theme from "../../../../styles/theme";
import {Context as mainContext} from "../../../../Context/mainContext";

const Card = ({ data }) => {
    const {changeItemId} = useContext(mainContext);
    const navigate = useNavigate();
    const width = 200;
    const rootTitle = data?.root?.title?.replace(/ /g,"_")
    const categoryTitle = data?.category?.title?.replace(/ /g,"_")
    const navigateToDetails=()=>{
        navigate({
            pathname: `/${rootTitle}/${categoryTitle}/${data?.id}`,
            state: {
                _id: data?.id,
            }
        })
      changeItemId(data?.id)
    }
    return (
        <div onClick={() => navigateToDetails()} style={{ cursor: "pointer",backgroundColor:Theme.colors.white }} className="m-3 border rounded custome_card">
            <Image className="rounded-top" width="170" height="150" src={data?.thumbnail} />
            <p className="p-1 m-0" style={{ width: 150, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{data.title}</p>
            <p className="p-1 m-0">US ${data.price_total}</p>
        </div>
    )
}

export default Card;