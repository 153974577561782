import React,{useContext} from "react";
import {useNavigate} from "react-router-dom";

import {Context as mainContext} from "../../../../Context/mainContext";

const CenteredSubCategory =({data,rootTitle})=>{
    const navigate = useNavigate();
    const {changeSubCategoryId,changeSubCategoryName} = useContext(mainContext);

    const navigateToSubCategory =()=>{
        const categoryTitle = data?.title?.replace(/ /g,"_")
        const roottitle = rootTitle?.replace(/ /g,"_")
        navigate({
            pathname: `/${roottitle}/${categoryTitle}`,
            state: {
                _subCategoryId: data?.id,
            }
        })
        changeSubCategoryId(data?.id)
    }

    return(
        <div onClick={()=>navigateToSubCategory()} style={{ cursor: "pointer"}} className="d-flex m-3 border p-2 custome_card w-100">
            <p className="p-0 m-auto">{data.title}</p>
        </div>
    )
}

export default CenteredSubCategory;