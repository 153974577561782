import React, { useEffect, useState, useContext } from "react";
import SweetAlert from 'react-bootstrap-sweetalert';
import { Image, Button,Dropdown,Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { Context as MainContext } from "../../../../Context/mainContext";
import { Context as AuthContext } from "../../../../Context/AuthContext";
import Theme from "../../../../styles/theme"

const Card = ({ item, index,maxQuantity }) => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const { changeCartItemsAuthContext } =useContext(AuthContext);
    const { state,changeCartItems,changeItemId } = useContext(MainContext);
    const [acceptDelete, setAcceptDelete] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [quantitySelected, setQuantitySelected] = useState(false);
    const [incommingIndex, setIndex] = useState(null);
    const [newImages, setNewImages] = useState([])
    const [dataBeforeDelete, setDataBeforeDelete] = useState([]);
    const [maxAvailableQuantity, setMaxAvailableQuantity] = useState([]);
    const [enteredQuantity, setEnteredQuantity] = useState(0);
    const [quantityError, setQuantityError] = useState("");

    useEffect(() => {
        setIndex(index)
        setNewImages(item?.image)
        const itemsArray = JSON.parse(localStorage.getItem('itemsArray')) || [];
        const commingAvailableQuantity = JSON.parse(localStorage.getItem('availableQuantity')) || [];
        const findItem=commingAvailableQuantity.findIndex(_=>_.id==item.id)
        setEnteredQuantity(commingAvailableQuantity[findItem]?.availableQuantity)
        setDataBeforeDelete(itemsArray)
        setMaxAvailableQuantity(commingAvailableQuantity)
    }, [deleted,quantitySelected,state.changeCartItems]);

    const showDeletedAlert = () => {
        localStorage.removeItem("itemsArray");
        localStorage.removeItem("availableQuantity");
        const newData = dataBeforeDelete.filter((item,index) => index != incommingIndex);
        const newQuantityData = maxAvailableQuantity.filter((item,index) => index != incommingIndex);
        addItems(newData,newQuantityData);
        setAcceptDelete(false);
        setDeleted(true);
        changeCartItems();
        changeCartItemsAuthContext();
    }

    const addItems = (itemsArray,quantityData) => {
        localStorage.setItem('itemsArray', JSON.stringify(itemsArray));
        localStorage.setItem('availableQuantity', JSON.stringify(quantityData));
    }

    const closeDeletedAlert = () => {
        setDeleted(false);
        changeCartItems();
        changeCartItemsAuthContext();
    }

    const closeQuantityAlert = () => {
        setQuantitySelected(false);
        changeCartItems();
        changeCartItemsAuthContext();
    }

    const addQuantityItems=(item)=>{
        const findingIndex = maxAvailableQuantity.findIndex((item,index)=>index==incommingIndex)
        maxAvailableQuantity[findingIndex]={...maxAvailableQuantity[findingIndex],availableQuantity:item}
        localStorage.setItem('availableQuantity', JSON.stringify(maxAvailableQuantity));
        setQuantitySelected(!quantitySelected);
    }
    const rootTitle = item?.root?.title?.replace(/ /g,"_")
    const categoryTitle = item?.category?.title?.replace(/ /g,"_")
    const navigateToDetails =()=>{
        navigate({
            pathname: `/${rootTitle}/${categoryTitle}/${item?.id}`,
            state: {
                _id: item.id,
            }
            })
      changeItemId(item?.id)
    }

    const navigateToSellerProfile =()=>{
        navigate({
            pathname: `/User/${item?.seller?.firstname} ${item?.seller?.lastname}`,
            state: {
                _id: item?.seller?.id,
            }
            })
    }

    var newNumber=[];
    const findItem = maxAvailableQuantity.find(quantityItem=>quantityItem.id==item.id)
    if (item?.quantity>0 ) {
        for (let index = 1; index <= item?.quantity; index++) {
            newNumber.push(index);
        }
    }
    const NoQuantity = t('your quntity not availabe');
    const NotValidQuantity = t('please Enter Valid quantity');
    const changeQuantity=()=>{
        if (enteredQuantity>item.quantity) {
            setQuantityError(NoQuantity)   
        } else if(enteredQuantity<1){
            setQuantityError(NotValidQuantity)
        } else {
            addQuantityItems(enteredQuantity)
            setQuantityError("")
        }
    }
    const handleKeypress = e => {
        if (e.key === 'Enter') {
            changeQuantity();
        }
    };
    const changeEnteredQuantity =(e)=>{
        var value = e?.target?.value
        value = (value.replace(/[٠١٢٣٤٥٦٧٨٩]/g, function (d) {
         return d.charCodeAt(0) - 1632;                
         }).replace(/[۰۱۲۳۴۵۶۷۸۹]/g, function (d) { return d.charCodeAt(0) - 1776; })
     );
        setEnteredQuantity(value);
    }
    return (
        <div>
            <p className="border-bottom" style={{ fontSize: 22 }}>{t('Seller')} <span onClick={()=>navigateToSellerProfile()} style={{cursor:"pointer",textTransform:"uppercase",color:Theme.colors.blue}}>{item?.seller?.firstname}{" "}{item?.seller?.lastname}</span></p>
            <div className="d-flex flex-column justify-content-between">
                <div className="d-flex">
                    <Image style={{cursor:"pointer"}} onClick={()=>navigateToDetails()} className="my-2" width="150" height="150" src={newImages[0]} />
                    <div className="w-100 d-flex flex-column">
                    <div className="w-100 d-flex justify-content-between">
                    <p className="ml-3 w-75" style={{ fontSize: 18, fontWeight: "bold" }}>{item?.title}</p>
                    <p style={{ fontSize: 18, fontWeight: "bold",overFlow: "auto",whiteSpace: "nowrap" }}>{item?.price_total}$ US</p>
                    </div>
                    <div className="d-flex flex-column flex-lg-row ml-3">
                        <p className="my-auto" style={{ color: Theme.colors.black }}>{t('Enter your quantity')}</p>
                        <Form.Control 
                            value={enteredQuantity}
                            onChange={(e)=>changeEnteredQuantity(e)}
                            onKeyPress={handleKeypress}
                            onBlur={()=>changeQuantity()}
                            className="border-right-0 border-left-0 border-top-0 border-bottom rounded-0 my-3 w-50"
                            placeholder={t('Enter your quantity')}
                        />
                    </div>
                        <p className="mx-3 my-auto" style={{ fontSize:12,color: Theme.colors.darkGrey }}>{t('max available quantity')}{" "}{item.quantity}</p>
                        <p className="mx-3 my-auto" style={{ fontSize:12,color: Theme.colors.danger }}>{quantityError}</p>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-end">
                <Button onClick={()=>setAcceptDelete(true)} variant="link">{t('Remove')}</Button>
                {
                    acceptDelete && <SweetAlert
                        title={t('Are you sure want to delete this item?')}
                        onConfirm={() => showDeletedAlert()}
                        onCancel={()=>setAcceptDelete(false)}
                        showCancel={true}
                        focusCancelBtn={true}
                    />
                }
                {
                    deleted && <SweetAlert
                        type="success"
                        title={t('Item deleted successful')}
                        onConfirm={()=>closeDeletedAlert()}
                        focusCancelBtn={true}
                    />
                }
                {
                    quantitySelected && <SweetAlert
                        type="success"
                        title={t('Quantity selected successful')}
                        onConfirm={()=>closeQuantityAlert()}
                        focusCancelBtn={true}
                    />
                }
            </div>
        </div>
    )
}

export default Card;
