export * from "./Constants";
export * from "./ConstantsAxios";
export * from "./Fetching";

export const logoutUtl = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("userId");
  localStorage.removeItem("itemsArray");
  localStorage.removeItem("availableQuantity");
  localStorage.removeItem("userData");
};
